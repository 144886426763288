body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.yellow-text{
  color: yellow;
}
.blue-text{
  color: blue;
}






/* transizione tra le pagine */
.page{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fade-appear .fade-enter{
  opacity: 0;
  z-index:1;
}
.fade-appear-active .fade-enter.fade-enter-active{
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}