
/* COLORS */
$lilla: #e7ceeb;
$lilla-chiaro: #f9f0fa;
$lilla-scuro: #cba1d2;
$lilla-scurissimo: darken(#A46DAE, 10);

$dark: lighten(black, 15); // #262626;

$grigio: #383836;
$verdino: #69897E;
$ocra: #B5892D;
$bordeaux: #9F3A39;
$marrone: #501200;
$grigio-chiaro: #EBEBE9;




body{
  font-family: 'Fira Sans', sans-serif;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  margin:0;
  padding: 0;
  overflow-x: hidden;
}

//.container-fluid{
  //max-width: 1400px;
//}

a{
  color: black;
  &:hover{
    color: $grigio;
    text-decoration: none;
  }
}

.page-height{
  min-height: 100vh;
}

.home-section{
  overflow-x: hidden;
}

h1, h2, h3{
  //font-family: 'Playfair Display', serif;
  font-weight: 300;
}

p{
  line-height: 1.6;
}

.title{
  //font-size: 8rem;
  font-size: 3.5rem;
  color: $grigio;
  font-weight: 300;
  letter-spacing: -5px;
  text-transform: uppercase;
    &-home{
      line-height: .8;
      font-size: 4.5rem;
    }

  @media (min-width: 576px) and (max-width: 767.98px){

  }
  @media (min-width: 768px) and (max-width: 991.98px){
    font-size: 5rem;
  }
  @media (min-width: 992px){
    font-size: 6.5rem;
  }
  @media (min-width: 1200px){
    font-size: 8rem;
    &-home{
      font-size: 120px;
    }
  }
}


.subtitle{
  font-size: 1.75rem;
  margin-top: -20px;
  color: $marrone;
  line-height: .8;
  &-home{
    //margin-top: -20px;
    padding-left: 5px;
    font-weight: 300;
    color: $marrone;
  }
  @media (min-width: 768px){
    margin-top: -30px;
    font-size: 2.5rem;
  }
  @media (min-width: 992px){
    margin-top: -40px;
  }
}

.general-link{
  color: $marrone;
  font-weight: 700;
  &-big{
    font-size: 1.5rem;
  }
}

.bg-grigio{
  background: $grigio;
  .title{
    color: white
  }
  .subtitle-home{
    color: white;
  }
}


.pt-120{
  padding-top: 100px;
}
.page{
  width: 100%;
  overflow-x: hidden;
}



/* Menu/Header ----------------- */
.logo{
  width: 170px;
  display: block;
  transition: all .35s ease;
  position: absolute;
  top:15px;
  left: 15px;
}
.js-header{
  width: 100vw;
  min-height:75px;
  &.navbar{
    padding:0; 
    transition: all .35s ease;
  }
}
.js-header.smaller {
  min-height: 75px;
  background: rgba(255,255,255,.8);
  // overflow: hidden;
}
.js-header.smaller .logo {
  width: 100px;
  height:70px;
  top:5px;
  overflow: hidden;
}
.navbar-toggler{
  position: absolute;
  top: 16px;
  right: 15px;
}
.navbar-nav{
  @media (max-width: 991.98px){
    height: 175px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 45px;
  }
}


/* Home ------------------ */

.carousel-item-home{
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.slider-studio{
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.percorso{
  .carousel-indicators{
    bottom: -60px;
    margin-left: 20%;
    margin-right: 20%;
    li{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #999;
    }
  }
  .carousel-control-next, .carousel-control-prev{
    bottom: -30px;
    height: 50px;
    width: 20%;
    top: 100%;
    opacity: .3;
    .carousel-control-next-icon{
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='999999' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
    }
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='999999' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
    }
  }
  .carousel-control-next{
    justify-content: flex-start;
  }
  .carousel-control-prev{
    justify-content: flex-end;
  }
}


.home-section-location{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;

  &:before{
    content:"";
    position: absolute;
    top: 0;
    left: 40%;
    width: 100%;
    height: 100px;
    background-image: url('./assets/img/brown-brushstroke.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -20% bottom;
    z-index: 10;
  }

  &:after{
    content:"";
    position: absolute;
    bottom: 0;
    left: -25%;
    width: 100%;
    height: 100px;
    background-image: url('./assets/img/brown-brushstroke.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% top;
    z-index: 10;
 }

}

//.location-box{
  // background-image: url('https://andyhooke.co.uk/wp-content/uploads/2018/02/yellow-brushstroke.png');
  //  background-size: 100% 100%;
  //  background-repeat: no-repeat;
  // background-position: center center;
  // padding: 120px
//}


.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
    background: -moz-linear-gradient(left,  rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
}




/* About ----------------- */

.quote{
  //font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  position: relative;
  color: $marrone;

  // &:before{
  //   content: "“";
  //   font-size: 20rem;
  //   position: absolute;
  //   top: 0px;
  //   left: -35px;
  //   line-height: .5;
  //   color: $grigio-chiaro;
  //   z-index: -1;
  // }
}




.cornice-3{
  &:before{
    content: "";
    position: absolute;
    z-index: -1;
    top: 9%;
    left: -15%;

    width: 100vw;
    height: 20px;
    background: url('./assets/img/brown-brushstroke.png');
    background-size: 100% 20px;
  }
}





/* Percorsi ------------- */
.percorso-wrap{
  // &:nth-child(2) .percorso{
  //   top: 130px;
  // }
  &:nth-child(n){
    .btn-primary{
      background: $verdino;
    }
    .card-subtitle{
      color: $verdino;
    }
    .percorso-imagewrap{
      position: relative;
      &:before{
        background: url('./assets/img/green-brushstroke.png');
        background-size: 100% 25px;
      }
    }
  }
  &:nth-child(2n){
    .btn-primary{
      background: $ocra;
    }
    .card-subtitle{
      color: $ocra;
    }
    .percorso-imagewrap{
      position: relative;
      &:before{
        left:-10%;
        background: url('./assets/img/ocre-brushstroke.png');
        background-size: 100% 25px;
      }
    }
  }
  &:nth-child(3n){
    .btn-primary{
      background: $bordeaux;
    }
    .card-subtitle{
      color: $bordeaux;
    }
    .percorso-imagewrap{
      position: relative;
      &:before{
        background: url('./assets/img/red-brushstroke.png');
        background-size: 100% 25px;
      }
    }
  }
}

.percorso{
  border: none;
}

.percorso-title{
  font-weight: 400;
  font-size: 2.5em;
}
.percorso-card{
  // background: $grigio;
  //width: 40%;
  margin-top: -350px;
  color:$grigio;
  position: relative;
  overflow: hidden;
  background: transparent;
}

.percorso-card-grigia{
  .card-body{
     background: $grigio;
    color:white;
  }
  .card-text{
    color:white;
  }
}

.percorso-imagewrap{
  position: relative;
  &:before{
    content: "";
    position: absolute;
    width:100%;
    height: 25px;
    display: block;
    bottom:-12px;
    left:10%;
    background: url('./assets/img/brown-brushstroke.png');
    background-size: 100% 25px;
    z-index: 10;
  }
}

.card-img-top{
   border-radius: 0;
}

.card-body{
  background: white;
  position: relative;
}

.percorso-link{
  &:hover{
    text-decoration: none;
    color: currentColor;
  }
}

.percorso-body{
  background: white;
  @media (min-width: 1200px){
    margin-top: -180px;
  }
}

.percorso-body h5{
  margin-bottom: 1.5rem;
  text-align: left;
  strong{
    //padding: 0 25px;
    //white-space: nowrap;
    margin: 5px 0;
    display: block;
  }
}
.percorso-body p:last-child{
  margin-bottom: 0;
}
.percorso-body a{
    color: black;
    &:hover{
      color: #383836;
      text-decoration: none;
    }
}
.percorso-body ul{
  padding-left: 20px;
}

.parallax-img{
  width: 2000%;
  margin-left: -500%;
  background-size: auto 100%;
  background-repeat: repeat-x;
}
.parallax-img.no-repeat{
  width: 100%;
  height: 80vh;
  min-height: 400px;
  margin-left: 0;
  background-repeat: no-repeat;
  background-size: 100%;
}

.card-title{
  //font-family: 'Playfair Display', serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  //color: $dark;
}

.card-subtitle{
  text-transform: uppercase;
  color: $marrone;
  font-size: 1rem;
}

.card-text{
  color: $dark;
}

.btn-primary{
  background: $marrone;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  &:hover, &:active, &:focus{
    background: $grigio !important;
    a{
      text-decoration: none;
    }
  }
  a{
    color: white;
    text-decoration: none;
  }
}




/* Blog ------------------------ */
.post-link{
  &:hover{
    color: currentColor;
    text-decoration: none;
    img{
      filter: grayscale(1);
    }
    .card-content{
      &:after{
        transition: all .25s ease;
        opacity: 1;
      }
    }
  }
  h3{
    font-weight: 400;
  }
}
.post{
  //padding-left: 130px;
  color: black;
  border: none;
  padding:0;
  border-radius: 0;
  
}
.card-content{
  //border-top: 20px solid $lilla-chiaro;
  position: relative;
  &:before, &:after{
    position: absolute;
    transition: all .35s ease;
  }
  &:before{
    
    @media (min-width: 768px){
      content: "";
      width:120%;
      height: 20px;
      display: block;
      top:-16px;
      left:-20%;
      background: url('./assets/img/brown-brushstroke.png');
      background-size: 100% 20px;
      z-index: 1;
    }
  }
  &:after{
    content: "Leggi";
    text-transform: uppercase;
    font-weight: 700;
    font-size: .95rem;
    right: 20px;
    color: $marrone;
    top:15px;
    opacity: 0;
  }
}
.post img{
  left: -15px;
  top: -15px;
  width: calc(100% + 15px);
  object-fit: cover;
  @media (min-width: 768px){
    
  }
  @media (min-width: 992px){
    max-width:320px;
    position: relative;
    z-index: 2;
    left: -15px;
    max-height:320px;
    height:auto;
  }
}
.post-date{
  text-transform: uppercase;
  color: $marrone;
}
.post-dove{
  opacity: .6;
}

.post-body{
  background: white;
}



/* collaborazioni -------------------------- */

.curriculum{
  text-transform: uppercase;
  font-weight: 700;
  font-size: .95rem;
  right: 20px;
  color: #501200;
  top: 15px;
  &:hover{
    color: #383836;
    text-decoration: none;
  }
}
.post-link.collaboratori{
  .card-content:after{
    content:'';
    display: none !important;
  }
}
.post-link.collaboratori{
  .post img{
    @media (min-width: 992px){
      max-width:41.666667%;
      // position: relative;
      // z-index: 2;
      left: -15px;
      // max-height:320px;
      // height:auto;
    }
  }
}


/* Contatti -------------------------- */
.contact-wrap{
  background: white;
}
.contact-label{
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
}
.contact-link{
  color: $marrone;
}

.contact-icon{
  //background: $lilla-chiaro;
}

.form-label, .custom-control-label{
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
}
.form-control{
  //border: $marrone 1px solid;
 
}

.form-control:focus {
  border-color: $marrone;
  box-shadow: 0 0 0 .2rem rgba($marrone,.65);
}

.form-error{
  color: black;
  font-weight: 700;
  &:not(:empty):before{
    content: "*** ";
    color: darken(red, 10%);
  }
}

.virtual-tour-wrapper{
  width: 100%;
  height: 500px;
  position: relative;

  .virtual-tour{
    width: 100%;
    height: 100%;
    border:0;
  }
}

.map-wrapper{
  width: 100%;
  height: 600px;
  position: relative;

//   &:before{
//     content:"";
//     position: absolute;
//     top: 0;
//     left: 30%;
//     width: 100%;
//     height: 100px;
//    background-image: url('./assets/img/brown-brushstroke.png');
//    background-size: cover;
//    background-repeat: no-repeat;
// background-position: -20% bottom;
// z-index: 10;

//   }

//   &:after{
//     content:"";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 100px;
//    background-image: url('./assets/img/brown-brushstroke.png');
//    background-size: cover;
//    background-repeat: no-repeat;
//   background-position: 50% top;
// z-index: 10;

//   }
}



footer{
 background: $grigio;
 color: white;

  a{
    color: white;
    font-weight: 700;
    &:hover{
      color: white;
      text-decoration: none;
    }
  }
}


.bg-grigio-chiaro{
  background: $grigio-chiaro;
}


.grecaptcha-badge{
  visibility: hidden;
}

.modaleAvviso{
  position: relative;
  .modal-header{
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    border: none;
    .close{
      font-size: 2rem;
    }
  }
  .footer-btn-action{
    background-color: #c3c3c3;
  }
  .modal-dialog{
    max-width: 480px;
  }
  .modal-body{
    padding: 0;
  }
}